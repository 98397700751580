import React from "react";
import Layout from "../components/layout";
import Title from "../components/title";
import Cta from "../components/cta";

import "../styles/object/component/typography.scss";
import "../styles/object/project/about.scss";
import "../styles/object/project/privacy.scss";

export default () => (
  <Layout title="Privacy Policy">
    <Title top="PRIVACY POLICY" topSub="個人情報保護方針" />
    <main className="l-main">
      <section className="privacy">
        <div className="c-heading">
          <h4 className="heading_main">PURPOSE OF USE</h4>
          <h5 className="c-heading_sub">個人情報の利用目的</h5>
        </div>
        <div className="container">
          <div>
            <div>
              当社はホームページのお問い合わせにご入力頂いた個人情報を次に示す利用目的の達成に必要な範囲内で取扱います。
            </div>
            <p>・お問い合わせに対応するため</p>
            <p>・採用応募者への採用情報の発信、採用選考</p>
          </div>
        </div>
      </section>
      <section className="privacy">
        <div className="c-heading">
          <h4 className="heading_main">DISCLOSURE TO THIRD PARTIES</h4>
          <h5 className="c-heading_sub">第三者への非開示</h5>
        </div>
        <div className="container">
          <div>
            当社は以下の場合を除き、お客様の承諾なく、個人情報を第三者に開示することはありません。
          </div>
          <p>・法令に基づく場合。</p>
          <p>
            ・人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき。
          </p>
          <p>
            ・公衆衛生の向上又は児童の健全な育成推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき。
          </p>
          <p>
            ・国の機関若しくは地方公共団体又はその受託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、
            <br className="break"></br>
            本人の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがあるとき。
          </p>
        </div>
      </section>
      <section className="privacy">
        <div className="c-heading">
          <h4 className="heading_main">SECURITY OF PERSONAL INFORMATION</h4>
          <h5 className="c-heading_sub">個人情報の安全性</h5>
        </div>
        <div className="container">
          <p>
            当社では、個人情報を個人情報に関するリスク(個人情報への不正なアクセス・個人情報の紛失・破壊・改ざん及び漏洩など)に対して、
            <br className="break"></br>
            技術面及び組織面において、個人情報保護に充分配慮した合理的な取り扱いを行い、厳重に取り扱い・保管・管理しています。
          </p>
        </div>
      </section>
      <Cta />
    </main>
  </Layout>
);
